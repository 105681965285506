export default {
    bizTypes(deptType) {
        const biz2 = [
            { key: 3, value: '批发出库' },
            { key: 8, value: '批发退货入库' },
        ];
        const biz3 = [
            { key: 4, value: '采购入库' },
            { key: 7, value: '退货出库' },
            { key: 19, value: '收银交易' },
            { key: 20, value: '收银退货' },
            { key: 24, value: '自建退货' },
        ];
        let commonBiz = [
            { key: 9, value: '单方采购入库' },
            { key: 10, value: '单方采购退货' },
            { key: 12, value: '报损出库' },
            { key: 13, value: '报溢入库' },
            { key: 17, value: '调拨出库' },
            { key: 18, value: '调拨入库' },
            { key: 23, value: '内购出库' },
            { key: 26, value: '系统初始化入库' },
            { key: 30, value: '系统初始化退货' },
            // { key: 33, value: '电商销售出库' },
            // { key: 34, value: '电商退货入库' },
        ];
        if (deptType == 2) {
            commonBiz = commonBiz.concat(biz2);
        } else if (deptType == 3) {
            commonBiz = commonBiz.concat(biz3);
        } else {
            commonBiz = commonBiz.concat(biz3).concat(biz2);
        }
        return commonBiz.sort((a, b) => {
            return a.key - b.key;
        });
    },

    purchaseTypes(deptType) {
        const biz3 = [
            { key: 4, value: '采购入库' },
            { key: 7, value: '退货出库' },
        ];
        const commonBiz = [
            { key: 9, value: '单方采购入库' },
            { key: 10, value: '单方采购退货' },
        ];
        if (deptType == 2) {
            return commonBiz;
        } else {
            return biz3.concat(commonBiz);
        }
    },

    name(type) {
        const bizType = {
            3: '批发出库',
            8: '批发退货入库',
            4: '采购入库',
            7: '退货出库',
            19: '收银交易',
            20: '收银退货',
            24: '自建退货',
            9: '单方采购入库',
            10: '单方采购退货',
            12: '报损出库',
            13: '报溢入库',
            17: '调拨出库',
            18: '调拨入库',
            23: '内购出库',
            26: '系统初始化入库',
            30: '系统初始化退货',
            33: '电商销售出库',
            34: '电商退货入库',
        };
        return bizType[type] || '';
    },
};
